// export function getCoreSymbolRule() {
//     return request({
//         url: "oz/pk-core-symbol-rule",
//         method: "get"
//     });
// }

// export function updateCoreSymbolRule(data) {
//     return request({
//         url: "oz/pk-core-symbol-rule",
//         method: "put",
//         data
//     });
// }

// export function getPriceChannelRule() {
//     return request({
//         url: "oz/pk-price-channel-rule",
//         method: "get"
//     });
// }

// export function updatePriceChannelRule(data) {
//     return request({
//         url: "oz/pk-price-channel-rule",
//         method: "put",
//         data
//     });
// }

export function getSymbolFeedConfig() {
    const mockData = {
        status: 200,
        data: [
            {
                id: 1046,
                core_symbol: "EUR/USD",
                symbol_group_path: "*",
                price_channel_id: "Plain",
                price_channel_id_convert: "",
                symbol: "EURUSD",
                security: "",
                feed_to_taker: 1,
                accept: 1,
                spread_ask: 8,
                spread_bid: -8,
                created_at: "2022-12-28 06:37:58",
                updated_at: "2022-12-28 06:37:58",
            },
            {
                id: 1047,
                core_symbol: "EUR/USD",
                symbol_group_path: "*",
                price_channel_id: "W",
                price_channel_id_convert: "-W",
                symbol: "EURUSD-W",
                security: "",
                feed_to_taker: 0,
                accept: 1,
                spread_ask: 0,
                spread_bid: 0,
                created_at: "2022-12-28 06:37:58",
                updated_at: "2022-12-28 06:37:58",
            },
            {
                id: 1048,
                core_symbol: "EUR/USD",
                symbol_group_path: "*",
                price_channel_id: "E",
                price_channel_id_convert: "-E",
                symbol: "EURUSD-E",
                security: "",
                feed_to_taker: 1,
                accept: 1,
                spread_ask: 0,
                spread_bid: 0,
                created_at: "2022-12-28 06:37:58",
                updated_at: "2022-12-28 06:37:58",
            },
            {
                id: 1049,
                core_symbol: "EUR/USD",
                symbol_group_path: "*",
                price_channel_id: "R",
                price_channel_id_convert: "-R",
                symbol: "EURUSD-R",
                security: "",
                feed_to_taker: 1,
                accept: 1,
                spread_ask: 0,
                spread_bid: 0,
                created_at: "2022-12-28 06:37:58",
                updated_at: "2022-12-28 06:37:58",
            },
            {
                id: 1050,
                core_symbol: "EUR/USD",
                symbol_group_path: "*",
                price_channel_id: "Cents",
                price_channel_id_convert: ".c",
                symbol: "EURUSD.c",
                security: "",
                feed_to_taker: 1,
                accept: 1,
                spread_ask: 8,
                spread_bid: -8,
                created_at: "2022-12-28 06:37:58",
                updated_at: "2022-12-28 06:37:58",
            },
            {
                id: 1051,
                core_symbol: "EUR/USD",
                symbol_group_path: "*",
                price_channel_id: "P",
                price_channel_id_convert: "",
                symbol: "EURUSD",
                security: "",
                feed_to_taker: 1,
                accept: 1,
                spread_ask: 5,
                spread_bid: -5,
                created_at: "2022-12-28 06:37:58",
                updated_at: "2022-12-28 06:37:58",
            },
            {
                id: 1070,
                core_symbol: "AUD/CAD",
                symbol_group_path: "*",
                price_channel_id: "Plain",
                price_channel_id_convert: "",
                symbol: "AUDCAD",
                security: "",
                feed_to_taker: 1,
                accept: 1,
                spread_ask: 8,
                spread_bid: -8,
                created_at: "2022-12-28 06:40:44",
                updated_at: "2022-12-28 06:40:44",
            },
            {
                id: 1071,
                core_symbol: "AUD/CHF",
                symbol_group_path: "*",
                price_channel_id: "Plain",
                price_channel_id_convert: "",
                symbol: "AUDCHF",
                security: "",
                feed_to_taker: 1,
                accept: 1,
                spread_ask: 8,
                spread_bid: -8,
                created_at: "2022-12-28 06:40:44",
                updated_at: "2022-12-28 06:40:44",
            },
            {
                id: 1072,
                core_symbol: "AUD/JPY",
                symbol_group_path: "*",
                price_channel_id: "Plain",
                price_channel_id_convert: "",
                symbol: "AUDJPY",
                security: "",
                feed_to_taker: 1,
                accept: 1,
                spread_ask: 8,
                spread_bid: -8,
                created_at: "2022-12-28 06:40:44",
                updated_at: "2022-12-28 06:40:44",
            },
            {
                id: 1073,
                core_symbol: "AUD/NZD",
                symbol_group_path: "*",
                price_channel_id: "Plain",
                price_channel_id_convert: "",
                symbol: "AUDNZD",
                security: "",
                feed_to_taker: 1,
                accept: 1,
                spread_ask: 8,
                spread_bid: -8,
                created_at: "2022-12-28 06:40:44",
                updated_at: "2022-12-28 06:40:44",
            },
            {
                id: 1074,
                core_symbol: "AUD/USD",
                symbol_group_path: "*",
                price_channel_id: "Plain",
                price_channel_id_convert: "",
                symbol: "AUDUSD",
                security: "",
                feed_to_taker: 1,
                accept: 1,
                spread_ask: 8,
                spread_bid: -8,
                created_at: "2022-12-28 06:40:44",
                updated_at: "2022-12-28 06:40:44",
            },
            {
                id: 1075,
                core_symbol: "CAD/CHF",
                symbol_group_path: "*",
                price_channel_id: "Plain",
                price_channel_id_convert: "",
                symbol: "CADCHF",
                security: "",
                feed_to_taker: 1,
                accept: 1,
                spread_ask: 8,
                spread_bid: -8,
                created_at: "2022-12-28 06:40:44",
                updated_at: "2022-12-28 06:40:44",
            },
            {
                id: 1076,
                core_symbol: "CAD/JPY",
                symbol_group_path: "*",
                price_channel_id: "Plain",
                price_channel_id_convert: "",
                symbol: "CADJPY",
                security: "",
                feed_to_taker: 1,
                accept: 1,
                spread_ask: 8,
                spread_bid: -8,
                created_at: "2022-12-28 06:40:44",
                updated_at: "2022-12-28 06:40:44",
            },
        ],
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}

export function updateSymbolFeedConfig(data) {
    const mockData = {
        status: 200,
        data: { msg: "Updated successfully" },
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
